html {
	box-sizing: border-box;
}

*, *:before, *:after {
	box-sizing: inherit;
	outline: none;
}

a, a:hover, a:focus, a:active, a:visited {
	outline: none;
}

html, body {
	width: 100%;
	height: 100%;
}

html {
	text-size-adjust: 100%;
}

body {
	background-color: #fff;
	
	@include font-size(14);
	font-family: $font-base;
	font-weight: 300;
	color: $color-text;
	
	@include mq($from: tablet) {
		background-color: $color-bg;
	}
	
	-ms-scroll-chaining: none;
	overscroll-behavior: none;
	
	.no-touch & {
		-webkit-font-smoothing: antialiased;
		-moz-osx-font-smoothing: grayscale;
	}

	&.noscroll {
		overflow: hidden;
	}
}

.wrapper {
	width: 100%;
	padding: 0;
	position: relative;

	@include mq($from: tablet) {
		width: 100%;
		//max-width: 1800px;
		margin: 0 auto;
		padding: 0;
	}
}

.harmonize {
	position: relative;
}

.loader {
	.cache {
		display: none;
		position: fixed;
		top: 0;
		left: 0;
		right: 0;
		bottom: 35px;
		z-index: 1000;

		@include mq($from: tablet) {
			bottom: 45px;
		}
	}

	&.active {
		.cache {
			display: block;
		}
	}
}


.clearfix {
	@include clearfix();
}


/*
##     ##  ########      ###     ########   ########   ########
##     ##  ##           ## ##    ##     ##  ##         ##     ##
##     ##  ##          ##   ##   ##     ##  ##         ##     ##
#########  ######     ##     ##  ##     ##  ######     ########
##     ##  ##         #########  ##     ##  ##         ##   ##
##     ##  ##         ##     ##  ##     ##  ##         ##    ##
##     ##  ########   ##     ##  ########   ########   ##     ##
 */


/* 
##     ##     ###     ####  ##    ##   
###   ###    ## ##     ##   ###   ##   
#### ####   ##   ##    ##   ####  ##   
## ### ##  ##     ##   ##   ## ## ##   
##     ##  #########   ##   ##  ####   
##     ##  ##     ##   ##   ##   ###   
##     ##  ##     ##  ####  ##    ##   
 */

.main-content {
	display: block;
	position: relative;
	height: 100%;
	overflow: hidden;

	// .touch & {
	// 	overflow: scroll;
	// }

	.wrapper {
		position: relative;

		@include mq($until: tablet) {
			height: 100%;
		}

		@include mq($from: desktop) {
			height: 100%;
		}
	}
}

/* 
 ######    ####  ########   ########   ########      ###     ########   
##    ##    ##   ##     ##  ##         ##     ##    ## ##    ##     ##  
##          ##   ##     ##  ##         ##     ##   ##   ##   ##     ##  
 ######     ##   ##     ##  ######     ########   ##     ##  ########   
      ##    ##   ##     ##  ##         ##     ##  #########  ##   ##    
##    ##    ##   ##     ##  ##         ##     ##  ##     ##  ##    ##   
 ######    ####  ########   ########   ########   ##     ##  ##     ##  
 */


/* 
########   ########    ######    ##     ##  ##         ########   
##     ##  ##         ##    ##   ##     ##  ##            ##      
##     ##  ##         ##         ##     ##  ##            ##      
########   ######      ######    ##     ##  ##            ##      
##   ##    ##               ##   ##     ##  ##            ##      
##    ##   ##         ##    ##   ##     ##  ##            ##      
##     ##  ########    ######     #######   ########      ##      
 */


/* 
########      ###     ##    ##   ########   
##     ##    ## ##    ###   ##   ##         
##     ##   ##   ##   ####  ##   ##         
########   ##     ##  ## ## ##   ######     
##         #########  ##  ####   ##         
##         ##     ##  ##   ###   ##         
##         ##     ##  ##    ##   ########   
 */

/* 
 #######   ##     ##  ########   ########   ##            ###     ##    ##   
##     ##  ##     ##  ##         ##     ##  ##           ## ##     ##  ##    
##     ##  ##     ##  ##         ##     ##  ##          ##   ##     ####     
##     ##  ##     ##  ######     ########   ##         ##     ##     ##      
##     ##   ##   ##   ##         ##   ##    ##         #########     ##      
##     ##    ## ##    ##         ##    ##   ##         ##     ##     ##      
 #######      ###     ########   ##     ##  ########   ##     ##     ##      
 */

.po {
	display: none;
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
	bottom: 35px;
	z-index: 40;
	overflow: scroll;
	opacity: 0;

	background-color: $color-1;
	background-color: rgba($color-1, 0.9);

	transition: opacity 0.2s ease-in-out;

	@include mq($from: tablet) {
		bottom: 45px;
	}

	&.show {
		display: block;
		opacity: 1;
	}
}

.po__wrapper {
	width: 100%;
	padding: 60px 20px 20px 20px;
	position: relative;

	@include mq($from: tablet) {
		max-width: 700px;
		margin: 0 auto;
	}
}

.po__image {
	display: inline-block;
	max-width: 100%;
	height: auto;
	margin: 0 0 1em 0;

	@include mq($from: tablet) {
		&.left {
			float: left;
			max-width: 50%;
			margin: 0 1em 1em 0;
		}

		&.right {
			float: right;
			max-width: 50%;
			margin: 0 0 1em 1em;
		}
	}
}

.po__close {
	display: block;
	width: 40px;
	height: 40px;
	position: absolute;
	top: 10px; 
	right: 20px;
	
	background-color: transparent;

	@include font-size(40);
	color: $color-text-light;
	line-height: 40px;
	text-align: center;

	&:before {
		display: inline-block;
		content: "fermer";
		position: absolute;
		right: 100%;
		top: 50%;
		margin-top: -19px;

		font-family: $font-alt;
		font-weight: 400;
		text-transform: uppercase;
		@include font-size(14);
		line-height: 40px;
	}

	transition: color 0.2s ease-in-out;

	.no-touch & {
		&:hover, &:focus {
			color: $color-cancel;
		}
	}
}

.po__list-feds {
	li {
		width: 150px;
		margin: 5px;
		padding: 5px;
		height: 70px;
		float: left;
		text-align: center;


		background-color: #fff;
	}

	img {
		display: inline-block;
		width: auto;
		max-width: 100%;
		height: 100%;

	}
}

/* 
########   ##    ##   ########    #######   
   ##       ##  ##    ##     ##  ##     ##  
   ##        ####     ##     ##  ##     ##  
   ##         ##      ########   ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##         ##     ##  
   ##         ##      ##          #######   
 */

.editor-text {
	color: $color-text-light;

	p {
		line-height: 1.5;
		margin-bottom: 1.5em;


	}

	a {
		color: $color-text-light;
		display: inline-block;
		border-bottom: 1px solid $color-text-light;

		transition:
			color 0.2s ease-in-out,
			border-color 0.2s ease-in-out;

		.no-touch & {
			&:hover, &:focus {
				color: lighten($color-4, 10%);
				border-color: lighten($color-4, 10%);
			}
		}

	}

	h1 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		text-transform: uppercase;
		@include font-size(20);
		font-weight: 700;
	}

	h2 {
		margin-bottom: 1.5em;
		font-family: $font-alt;
		@include font-size(16);
	}

	h3 {
		margin-bottom: 1em;
		font-weight: 600;
	}

	.note {
		font-style: italic;
		color: darken($color-text-light, 15%);
	}

	.num-azur {
		display: inline-block;
		height: 48px;
		line-height: 40px;
		margin: -0.5em 0 0.5em 0;
		padding: 0 10px 0 0;


		background-color: #fff;
		border: 4px solid #00ADEF;
		border-radius: 24px;

		@include font-size(20);
		font-weight: 700;
		color: #00ADEF;
	}

	.num-azur__label {
		display: inline-block;
		margin-right: 5px;
		margin: -4px 5px -4px -4px;
		line-height: 48px;
		padding: 0 15px;

		background-color: #00ADEF;
		border-radius: 24px;
		color: #fff;
	}
}


/* 
##     ##     ###     ########    ######    
###   ###    ## ##    ##     ##  ##    ##   
#### ####   ##   ##   ##     ##  ##         
## ### ##  ##     ##  ########    ######    
##     ##  #########  ##               ##   
##     ##  ##     ##  ##         ##    ##   
##     ##  ##     ##  ##          ######    
 */

.map-overlay {
}

.overlay-content {
	max-width: 320px;
	padding: 10px;
	position: relative;
	margin:0 10px;

	background: #fff;

	@include font-size(14);
	text-transform: uppercase;
	color: $font-base;

	box-shadow: 1px 1px 10px darken(#E4E4E4,20%);

	h6 {
		font-weight: 700;
	}

}
