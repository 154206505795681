
.map-container {
	display: block;
	width: 100%;
	
	@include mq($until: tablet) {
		position: absolute;
		top: 0;
		left: 0;
		bottom: 35px;
		z-index: 1;
	}
	
	@include mq(tablet, desktop) {
		height: 500px;
	}
	
	@include mq($from: desktop) {
		display: block;
		position: absolute;
		width: percentage(2/3);
		top: 0;
		left: percentage(1/3);
		bottom: 45px;
		z-index: 1;
	}
	
	@include mq(xwide) {
		left: percentage(1/4);
		width: percentage(3/4);
	}
	
}