
.sidebar {
	@include clearfix;
	display: block;
	width: 100%;
	
	background-color: #fff;
	
	@include mq($from: tablet) {
		width: 100%;
		padding: 20px;
		position: relative;
		z-index: 3;
	}
	
	@include mq($from: desktop) {
		position: absolute;
		width: percentage(1/3);
		top: 0;
		bottom: 45px;
		left: 0;
		
		padding: 10px 10px 10px 30px;
		overflow: hidden;
	}
	
	@include mq($from: xwide) {
		width: percentage(1/4);
	}
}

.sidebar__helper {
	display: none;
	
	@include mq($from: desktop) {
		display: block;
		width: percentage(1/3);
		height: 40px;
		position: absolute;
		top: 0;
		left: 0;
		z-index: 4;
		
		background-color: #fff;
	}
	
	@include mq($from: xwide) {
		width: percentage(1/4);
	}
	
	a {
		display: none;
		width: 100%;
		height: 100%;
		padding: 0 30px;
		opacity: 0;
		line-height: 40px;
		
		background-color: #fff;
		border-bottom: 1px solid #ddd;
		
		font-family: $font-alt;
		text-transform: uppercase;
		@include font-size(16);
		color: $color-text;
		
		transition: background-color 0.2s ease-in-out, opacity 0.2s ease-in-out;
		
		.no-touch & {
			&:hover {
				background-color: darken($color-bg, 10%);
			}
		}
		
		&.show {
			display: block;
			opacity: 1;
		}
	}
}

.sidebar__filter-activesearch {
	@include mq($until: tablet) {
		display: block;
		width: calc(100% - 36px);
		height: 65px;
		padding: 0;
		position: fixed;
		top: -65px;
		left: 18px;
		z-index: 2;
		
		background-color: #fff;
		border-radius: 50px;
		
		transition: top 0.2s ease-in-out;
		
		.mapshow & {
			top: 23px;
			transition: top 0.2s ease-in-out 0.2s;
		}
		
		.btn {
			display: flex;
			flex-wrap: wrap;
			align-items: center;
			justify-content: center;
			
			width: 100%;
			height: 100%;
			padding: 0 0 0 63px;
			position: relative;
			line-height: 1.4;
			
			font-family: $font-alt;
			@include font-size(13);
			font-weight: 300;
			text-align: left;
			color: $color-text;
			
			&:before {
				display: block;
				width: 63px;
				height: 65px;
				line-height: 65px;
				position: absolute;
				top: 0;
				left: 0;
				
				background-color: rgba(#8097C0, .3);
				border-radius: 50px 0 0 50px;
				
				font-family: 'ffb-rge';
				@include font-size(20);
				content: "\e610";
				text-align: center;
				
			}
		}
	}
	
	@include mq($from: tablet) {
		display: none;
	}
}

.sidebar__filter-container {
	@include mq($until: tablet) {
		width: 100%;
		padding: 10px;
		position: fixed;
		top: 0;
		bottom: 0;
		right: 0;
		z-index: 11;
		
		background-color: #fff;
		
		transition: right 0.2s ease-in-out 0.2s;
		
		display: flex;
		flex-direction: column;
		justify-content: center;
		
		.touch & {
			overflow: scroll;
		}
		
		.mapshow & {
			right: 100%;
			transition: right 0.2s ease-in-out;
		}
	}
}

.sidebar__filter,
.sidebar__result {
	@include mq(tablet, desktop) {
		width: 50%;
		float: left;
	}
}

.sidebar__filter {
	@include mq($until: tablet) {
		display: block;
	}
	
	@include mq(tablet, desktop) {
		padding-right: 10px;
	}
	
	@include mq($from: desktop) {
		position: relative;
		margin-top: 10px;
		z-index: 5;
	}
}

.sidebar__mobile-btn {
	display: block;
	width: 100%;
	height: 50px;
	margin: 0 auto;
	padding: 0 10px;
	line-height: 50px;
	position: relative;
	
	background-color: $color-4;
	border: none;
	border-radius: 13px;
	
	font-family: $font-alt;
	font-size: 14px;
	text-transform: uppercase;
	color: $color-text-light;
	text-align: center;
	
	transition:
			background-color 0.2s ease-in-out,
			width 0.2s ease-in-out,
			border-radius 0.2s ease-in-out;
	
	.house &{
		border-radius: 0 0 20px 20px;
		margin: 0 -20px;
		width: calc( 100% + 40px );
	}
	
	&:disabled{
		background-color: rgba($color-4, .3);
	}
	
	@include mq($until: tablet) {
		//position: absolute;
		//left: 50%;
		//bottom: 0;
		//border-radius: 0;
		//transform: translateX(-50%);
	}
	
	.label {
		display: block;
		opacity: 1;
		transition: opacity 0.2s ease-in-out 0.2s;
	}
	
	.loader {
		display: block;
		width: 50px;
		height: 50px;
		
		position: absolute;
		top: 0;
		left: 50%;
		margin-left: -25px;
		
		opacity: 0;
		transition: opacity 0 ease-in-out 0;
	}
	
	.no-touch & {
		&:hover, &:focus {
			background-color: lighten($color-4, 5%);
		}
	}
	
	&.active {
		width: 50px;
		border-radius: 50px;
		
		background: $color-4 url('#{$image-path}loaderbis.gif') center center no-repeat;
		background-size: 40px;
		
		.label {
			transition: opacity 0 ease-in-out 0;
			opacity: 0;
		}
		
		.loader {
			opacity: 1;
			transition: opacity 0.2s ease-in-out 0.2s;
		}
	}
}

.pac-item{
	& > span:last-of-type{
		display: none;
	}
}

.sidebar__search {
	display: block;
	position: relative;
	
	background-color: #fff;
	
	.sidebar__search-input,
	.sidebar__search-select select{
		@include input_mobile();
		display: block;
		width: 100%;
		padding: 15px 10px;
		margin: 20px 0;
		
		border: 1px solid $color-4;
		border-radius: 13px;
		background-color: $white;
		
		font-family: $font-alt;
		font-size: 14px;
		color: $color-2;
		
		&::placeholder {
			font-family: $font-alt;
			font-size: 14px;
			color: $color-2;
		}
		
		&:invalid {
			border: 1px solid rgba($color-2, .5);
		}
		
		.no-touch & {
			transition: border-color 0.2s ease-in-out;
			
			&:hover, &:focus {
				border-color: $color-4;
			}
		}
	}
	
	.sidebar__search-select{
		position: relative;
		&:after{
			content: "";
			position: absolute;
			right: 20px;
			top: 50%;
			width: 12px;
			height: 12px;
			border-right: 2px solid #8097C0;
			border-bottom: 2px solid #8097C0;
			transform: translateY(-50%) rotate(45deg);
		}
	}
}

.sidebar__result {
	@include mq($until: tablet) {
		display: none;
	}
	
	@include mq($from: tablet) {
		display: block;
	}
	
	@include mq(tablet, desktop) {
		margin-top: 0;
		padding-left: 10px;
		padding-right: 0;
		position: absolute;
		top: 20px;
		bottom: 20px;
		left: 50%;
		overflow: hidden;
	}
	
	@include mq($from: desktop) {
		margin-top: 20px;
		margin-bottom: 10px;
		z-index: 4;
	}
}