
.header-main {
	
	display: block;
	width: 100%;
	
	text-align: center;
}

.header-main__title {
	display: block;
	
	font-family: $font-alt;
	font-size: 20px;
	font-weight: 600;
	line-height: 1.2em;
	color: $color-2;
}

.header-main__logo {
	display: block;
	width: 205px;
	height: 103px;
	margin: 30px auto 20px auto;
	text-indent: -9999px;
	
	background: transparent url('/images/logo-desktop.png') top center no-repeat;
	background-size: 100% 100%;
	
	@include retina {
		background-image: url('/images/logo-desktop@2x.png');
	}
}