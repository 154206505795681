// SweetAlert
// 2014 (c) - Tristan Edwards
// github.com/t4t5/sweetalert

.sweet-overlay {
	background-color: rgb(0, 0, 0); /* IE8 */
	-ms-filter:"progid:DXImageTransform.Microsoft.Alpha(Opacity=40)"; /* IE8 */
	background-color: rgba(black, 0.4);

	position: fixed;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;

	display: none;
	z-index: 10000;
}

.sweet-alert {
	$width: 478px;
	$padding: 17px;

	background-color: white;
	font-family: $font-base;
	width: $width;
	padding: $padding;
	border-radius: 2px;
	text-align: center;

	position: fixed;
	left: 50%;
	top: 50%;
	margin-left: -($width/2 + $padding);
	margin-top: -200px;

	overflow: hidden;
	display: none;
	z-index: 99999;

	@media all and (max-width: 540px) {
		width: auto;
		margin-left: 0;
		margin-right: 0;

		left: 15px;
		right: 15px;
	}

	h2 {
		color: #575757;
		font-size: 30px;
		text-align: center;
		font-weight: 600;
		text-transform: none;
		position: relative;
		margin: 25px 0;
		padding: 0;
		line-height: 40px;
		display: block;
	}

	p {
		color: #797979;
		font-size: 16px;
		text-align: center;
		font-weight: 300;
		position: relative;
		text-align: inherit;
		float: none;
		margin: 0;
		padding: 0;
		line-height: normal;
	}

	button {
		$btnBlue: #AEDEF4;
		$btnGray: #D0D0D0;

		background-color: $btnBlue;
		color: white;
		border: none;
		box-shadow: none;
		font-size: 17px;
		font-weight: 500;
		-webkit-border-radius: 4px;
		border-radius: 5px;
		padding: 10px 32px;
		margin: 26px 5px 0 5px;
		cursor: pointer;
		&:focus {
			outline: none;
			box-shadow: 0 0 2px rgba(128, 179, 235, 0.5), inset 0 0 0 1px rgba(0, 0, 0, 0.05);
		}
		&:hover {
			background-color: darken($btnBlue, 3%);
		}
		&:active {
			background-color: darken($btnBlue, 10%);
		}
		&.cancel {
			background-color: $btnGray;
			&:hover {
				background-color: darken($btnGray, 3%);
			}
			&:active {
				background-color: darken($btnGray, 10%);
			}
			// Cancel button should keep the same style
			&:focus {
				box-shadow: rgba(197, 205, 211, 0.8) 0px 0px 2px, rgba(0, 0, 0, 0.0470588) 0px 0px 0px 1px inset !important;
			}
		}
		// Removes selection outline in Firefox
		&::-moz-focus-inner {
		  border: 0;
		}
	}

	// Only show focus-style when there is multiple choice of actions
	&[data-has-cancel-button=false] button {
		box-shadow: none !important;
	}

	&[data-has-confirm-button=false][data-has-cancel-button=false] {
		padding-bottom: 40px;
	}

	.sa-icon {
		$red: 		#F27474;
		$orange: 	#F8BB86;
		$blue: 		#C9DAE1;
		$green: 	#A5DC86;

		width: 80px;
		height: 80px;
		border: 4px solid gray;
		-webkit-border-radius: 40px;
		border-radius: 40px;
		border-radius: 50%;
		margin: 20px auto;
		padding: 0;
		position: relative;
		box-sizing: content-box;

		&.sa-error {
			border-color: $red;

			.sa-x-mark {
				position: relative;
				display: block;
			}

			.sa-line {
				position: absolute;
				height: 5px;
				width: 47px;
				background-color: $red;
				display: block;
				top: 37px;
				border-radius: 2px;

				&.sa-left {
					-webkit-transform: rotate(45deg);
    			transform: rotate(45deg);
    			left: 17px;
				}
				&.sa-right {
					-webkit-transform: rotate(-45deg);
    			transform: rotate(-45deg);
    			right: 16px;
				}
			}
		}
		&.sa-warning {
			border-color: $orange;

			.sa-body { // Exclamation mark body
				position: absolute;
				width: 5px;
				height: 47px;
				left: 50%;
				top: 10px;
				-webkit-border-radius: 2px;
				border-radius: 2px;
				margin-left: -2px;
				background-color: $orange;
			}
			.sa-dot { // Exclamation mark dot
				position: absolute;
				width: 7px;
				height: 7px;
				-webkit-border-radius: 50%;
				border-radius: 50%;
				margin-left: -3px;
				left: 50%;
				bottom: 10px;
				background-color: $orange;
			}
		}
		&.sa-info {
			border-color: $blue;

			&::before { // i-letter body
				content: "";
				position: absolute;
				width: 5px;
				height: 29px;
				left: 50%;
				bottom: 17px;
				border-radius: 2px;
				margin-left: -2px;
				background-color: $blue;
			}
			&::after { // i-letter dot
				content: "";
				position: absolute;
				width: 7px;
				height: 7px;
				border-radius: 50%;
				margin-left: -3px;
				top: 19px;
				background-color: $blue;
			}
		}
		&.sa-success {
			border-color: $green;

			&::before, &::after { // Emulate moving circular line
				content: '';
				-webkit-border-radius: 40px;
				border-radius: 40px;
				border-radius: 50%;
			  position: absolute;
			  width: 60px;
				height: 120px;
			  background: white;
				-webkit-transform: rotate(45deg);
			  transform: rotate(45deg);
			}
			&::before {
				-webkit-border-radius: 120px 0 0 120px;
			  border-radius: 120px 0 0 120px;
			  top: -7px;
				left: -33px;

			   -webkit-transform: rotate(-45deg);
			  transform: rotate(-45deg);
				-webkit-transform-origin: 60px 60px;
			  transform-origin: 60px 60px;
			}
			&::after {
				-webkit-border-radius: 0 120px 120px 0;
			  border-radius: 0 120px 120px 0;
			  top: -11px;
				left: 30px;

			  -webkit-transform: rotate(-45deg);
			  transform: rotate(-45deg);
				-webkit-transform-origin: 0px 60px;
			  transform-origin: 0px 60px;
			}

			.sa-placeholder { // Ring
				width: 80px;
				height: 80px;
				border: 4px solid rgba($green, 0.2);
				-webkit-border-radius: 40px;
				border-radius: 40px;
				border-radius: 50%;
				box-sizing: content-box;

				position: absolute;
				left: -4px;
				top: -4px;
				z-index: 2;
			}

			.sa-fix {	// Hide corners left from animation
				width: 5px;
				height: 90px;
				background-color: white;

				position: absolute;
				left: 28px;
				top: 8px;
				z-index: 1;

				-webkit-transform: rotate(-45deg);
				transform: rotate(-45deg);
			}

			.sa-line {
				height: 5px;
				background-color: $green;
				display: block;
				border-radius: 2px;

				position: absolute;
				z-index: 2;

				&.sa-tip {
					width: 25px;

    			left: 14px;
					top: 46px;

					-webkit-transform: rotate(45deg);
    			transform: rotate(45deg);
				}
				&.sa-long {
					width: 47px;

    			right: 8px;
					top: 38px;

					-webkit-transform: rotate(-45deg);
    			transform: rotate(-45deg);
				}
			}
		}
		&.sa-custom {
			background-size: contain;
			border-radius: 0;
			border: none;
			background-position: center center;
			background-repeat: no-repeat;
		}
	}

}

/*
 * Animations
 */

@mixin keyframes($animation-name) {
  @-webkit-keyframes #{$animation-name} {
    @content;
  }
  @keyframes #{$animation-name} {
    @content;
  }
}
@mixin animation($str) {
  -webkit-animation: #{$str};
  animation: #{$str};
}


// Modal animation

@include keyframes(showSweetAlert) {
  0% 		{ transform: scale(0.7); 	-webkit-transform: scale(0.7); 	}
	45% 	{ transform: scale(1.05); -webkit-transform: scale(1.05); }
	80% 	{ transform: scale(0.95); -webkit-tranform: scale(0.95); 	}
	100% 	{ transform: scale(1); 		-webkit-transform: scale(1); 		}
}
@include keyframes(hideSweetAlert) {
  0% 		{ transform: scale(1); 		-webkit-transform: scale(1); 		}
	100% 	{ transform: scale(0.5); 	-webkit-transform: scale(0.5); 	}
}

.showSweetAlert {
  @include animation('showSweetAlert 0.3s');

  &[data-animation=none] {
		@include animation('none');
	}
}
.hideSweetAlert {
  @include animation('hideSweetAlert 0.2s');

  &[data-animation=none] {
		@include animation('none');
	}
}



// Success icon animation

@include keyframes(animateSuccessTip) {
	0%		{ width: 0; 		left: 1px; 		top: 19px; }
	54%		{ width: 0; 		left: 1px; 		top: 19px; }
	70%		{ width: 50px; 	left: -8px; 	top: 37px; }
	84%		{ width: 17px; 	left: 21px; 	top: 48px; }
	100%	{ width: 25px; 	left: 14px; 	top: 45px; }
}
@include keyframes(animateSuccessLong) {
	0%		{ width: 0; 		right: 46px; 	top: 54px; }
	65%		{ width: 0; 		right: 46px; 	top: 54px; }
	84%		{ width: 55px; 	right: 0px; 	top: 35px; }
	100%	{ width: 47px; 	right: 8px; 	top: 38px; }
}
@include keyframes(rotatePlaceholder) {
	0% 		{ transform: rotate(-45deg); 	-webkit-transform: rotate(-45deg); 	}
	5% 		{ transform: rotate(-45deg); 	-webkit-transform: rotate(-45deg);		}
  12% 	{ transform: rotate(-405deg); -webkit-transform: rotate(-405deg);	}
  100% 	{ transform: rotate(-405deg); -webkit-transform: rotate(-405deg);	}
}

.animateSuccessTip {
	@include animation('animateSuccessTip 0.75s');
}
.animateSuccessLong {
	@include animation('animateSuccessLong 0.75s');
}
.sa-icon.sa-success.animate::after {
	@include animation('rotatePlaceholder 4.25s ease-in');
}


// Error icon animation

@include keyframes(animateErrorIcon) {
	0%		{ transform: rotateX(100deg); -webkit-transform: rotateX(100deg); opacity: 0; }
  100% 	{ transform: rotateX(0deg); 	-webkit-transform: rotateX(0deg); 	opacity: 1; }
}
.animateErrorIcon {
	@include animation('animateErrorIcon 0.5s');
}
@include keyframes(animateXMark) {
	0% 		{ transform: scale(0.4); 	-webkit-transform: scale(0.4); 	margin-top: 26px;	opacity: 0; }
	50% 	{ transform: scale(0.4); 	-webkit-transform: scale(0.4); 	margin-top: 26px;	opacity: 0; }
	80% 	{ transform: scale(1.15); -webkit-transform: scale(1.15); margin-top: -6px; 						}
	100% 	{ transform: scale(1); 		-webkit-transform: scale(1);		margin-top: 0; 		opacity: 1; }
}
.animateXMark {
	@include animation('animateXMark 0.5s');
}

@include keyframes(pulseWarning) {
	0%		{ border-color: #F8D486; }
	100%	{ border-color: #F8BB86; }
}
.pulseWarning {
	@include animation('pulseWarning 0.75s infinite alternate');
}

@include keyframes(pulseWarningIns) {
	0%		{ background-color: #F8D486; }
	100%	{ background-color: #F8BB86; }
}
.pulseWarningIns {
	@include animation('pulseWarningIns 0.75s infinite alternate');
}
