
.sliding-pane {
	display: block;
	width: 90%;
	position: fixed;
	z-index: 4;
	top: 0;
	left: 100%;
	bottom: 35px;
	//overflow: scroll;
	
	background-color: $color-3;
	border-radius: 0 30px 30px 0;
	box-shadow: 10px 0px 25px rgba(0, 0, 0, 0.07);
	
	@include font-size(16);
	line-height: 1.5;
	color: $color-text;
	
	transition:	left 0.2s ease-in-out;
	
	@include mq($until: tablet) {
		padding: 15px 30px 15px 20px;
	}
	
	@include mq(tablet, desktop) {
		width: 50%;
		left: -50%;
	}
	
	@include mq($from: tablet) {
		padding: 20px;
		background-color: rgba($color-3, 0.98);
		bottom: 45px;
	}
	
	@include mq($from: desktop) {
		position: absolute;
		width: percentage(1/3);
		padding: 60px 30px 30px 30px;
		top: 0;
		left: 0;
		bottom: 45px;
		z-index: 2;
	}
	
	@include mq($from: xwide) {
		width: percentage(1/4);
	}
	
	&.show {
		left: 0;
		
		@include mq(tablet, desktop) {
			left: 0;
		}
		
		@include mq($from: desktop) {
			left: 33%;
		}
		
		@include mq($from: wide) {
			left: 33%;
		}
		
		@include mq($from: xwide) {
			left: percentage(1/4);
		}
	}
	
	[class^="icon-"] {
		margin-right: 10px;
	}
	
	a:not(.sp__close) {
		color: $color-text;
		
		.no-touch & {
			border-bottom: 1px solid transparent;
			
			transition: border-color 0.2s ease-in-out;
			
			&:hover, &:focus {
				border-color: $color-text;
			}
		}
		
		.touch & {
			border-bottom: 1px solid $color-text;
		}
	}
}

.sliding-pane__inner{
	height: 100%;
}

.sp__close {
	display: block;
	width: 40px;
	height: 40px;
	position: fixed;
	top: 10px;
	right: 10%;
	
	background-color: transparent;
	
	@include font-size(40);
	color: $color-text;
	line-height: 40px;
	text-align: center;
	
	@include mq($from: tablet) {
		position: absolute;
		right: 10px;
	}
	
	transition: transform 0.2s ease-in-out;
	
	&.hide{
		display: none;
	}
	
	.no-touch & {
		&:hover, &:focus {
			transform: scale(1.2);
		}
	}
}

.sp__header {
	@include clearfix;
	
	@include mq($from: tablet) {
		margin: 20px 0;
	}
	
	@include mq($from: desktop) {
		margin: 10px 0 40px 0;
	}
}

.sp__name {
	font-family: $font-alt;
	font-weight: 400;
	text-transform: uppercase;
	line-height: 1.1em;
	@include font-size(28);
	
	@include mq($from: desktop) {
		@include font-size(36);
	}
}

.sp__siret {
	margin-top: 10px;
	font-family: $font-alt;
	font-weight: 300;
	text-transform: uppercase;
	@include font-size(20);
}

.sp__address {
	margin: 20px 0;
}

.sp__contact,
.sp__domain {
	margin-bottom: 20px;
}

.sp__domain {
	padding: 20px 0;
	
	li {
		display: block;
		line-height: 35px;
		margin-bottom: 5px;
		background-color: #fff;
		border-radius: 20px;
		padding: 0 10px;
		
		&:last-of-type {
			margin-bottom: 0;
		}
		
		font-family: $font-alt;
		font-weight: 300;
		text-align: center;
		color: $color-4;
	}
	
	@include mq($from: desktop) {
		display: flex;
		flex-wrap: wrap;
		flex-direction: column;
		li {
			align-self: flex-start
		}
	}
}

.sp__logo {
	display: block;
	width: 120px;
	height: auto;
	margin-bottom: 20px;
}

.sp__logo--entreprise {
	width: 100%;
	max-width: 120px;
	height: auto;
	margin-bottom: 20px;
}

.sp__fed {
	display: block;
	margin-bottom: 20px;
}

.sp__note {
	display: block;
	
	font-family: $font-alt;
	font-weight: 300;
	font-size: 10px;
	
	color: $color-1;
}
