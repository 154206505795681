@font-face {
	font-family: 'ffb-rge';
	src:url('/fonts/ffb-rge.eot?a506ze');
	src:url('/fonts/ffb-rge.eot?#iefixa506ze') format('embedded-opentype'),
		url('/fonts/ffb-rge.woff?a506ze') format('woff'),
		url('/fonts/ffb-rge.ttf?a506ze') format('truetype'),
		url('/fonts/ffb-rge.svg?a506ze#ffb-rge') format('svg');
	font-weight: normal;
	font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
	font-family: 'ffb-rge';
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-print:before {
	content: "\e611";
}

.icon-globe:before {
	content: "\e604";
}

.icon-call:before {
	content: "\e601";
}

.icon-send:before {
	content: "\e602";
}

.icon-keyboard-arrow-down:before {
	content: "\e603";
}

.icon-navigate-before:before {
	content: "\e610";
}

.icon-navigate-next:before {
	content: "\e612";
}

.icon-mcube:before {
	content: "\e600";
}

.icon-chaudiere-condensation:before {
	content: "\e605";
}

.icon-energie-bois:before {
	content: "\e606";
}

.icon-equipements-electriques:before {
	content: "\e607";
}

.icon-fenetres-volets-portes:before {
	content: "\e608";
}

.icon-isolation-mur:before {
	content: "\e609";
}

.icon-isolation-toiture:before {
	content: "\e60a";
}

.icon-pompes-a-chaleur:before {
	content: "\e60b";
}

.icon-renovation-globale:before {
	content: "\e60c";
}

.icon-solaire-photovoltaique:before {
	content: "\e60d";
}

.icon-solaire-thermique:before {
	content: "\e60e";
}

.icon-ventilation:before {
	content: "\e60f";
}

.icon-forage-geothermique:before {
	content: "\e900";
}