
.result__item {
	display: block;
	
	&:last-of-type {
		margin-bottom: 0;
	}
}

.result__btn {
	display: flex;
	align-items: center;
	padding: 15px 0;
	
	border-bottom: 1px solid rgba($color-border, .4);
	
	color: $color-3;
	
	.no-touch & {
		transition: border-color 0.2s ease-in-out;
		&:hover, &:focus {
			border-color: $color-4;
			.result__rs, .result__ville {
				color: $color-4;
			}
		}
	}
}

.result__rs {
	transition: color 0.2s ease-in-out;
	font-family: $font-alt;
	font-weight: 600;
	color: $color-1;
	font-size :16px;
}

.result__ville {
	transition: color 0.2s ease-in-out;
	text-transform: capitalize;
	font-weight: 300;
	color: $color-1;
	font-size: 16px;
	
	&:before{
		content: "-";
		display: inline-block;
		margin: 0 2px;
	}
}

.result__icon {
	@include clearfix;
	margin-top: 5px;
	
	span {
		display: inline-block;
		width: 25px;
		height: 25px;
		margin-right: 2px;
		
		background-color: red;
		border-radius: 2px;
		line-height: 25px;
		
		@include font-size(16);
		text-align: center;
		color: $color-text-light;
		
		&.icon-isolation-toiture       { background-color: $color-isolation-toiture; }
		&.icon-isolation-mur           { background-color: $color-isolation-mur; }
		&.icon-fenetres-volets-portes  { background-color: $color-fenetre; }
		&.icon-ventilation             { background-color: $color-ventilation; }
		&.icon-chaudiere-condensation  { background-color: $color-chaudiere-condensation; }
		&.icon-equipements-electriques { background-color: $color-equipements-electriques; }
		&.icon-solaire-thermique       { background-color: $color-solaire-thermique; }
		&.icon-solaire-photovoltaique  { background-color: $color-solaire-photovoltaique; }
		&.icon-energie-bois            { background-color: $color-energie-bois; }
		&.icon-pompes-a-chaleur        { background-color: $color-pompes-a-chaleur; }
		&.icon-forage-geothermique     { background-color: $color-forage-geothermique; }
		&.icon-renovation-globale      { background-color: $color-renovation-globale; }
	}
}