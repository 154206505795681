.gohouse{
	position: absolute;
	z-index: 10;
	top: 20px;
	right: 20px;
	width: 100px;
	height: 100px;
	
	/*background: #FFFFFF;
	border-radius: 50%;*/
	
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	
	&:before{
		transition: transform 0.3s ease-in-out;
		content: "";
		position: absolute;
		z-index: 0;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
		background-color: #fff;
		border-radius: 50%;
	}
	
	img{
		position: relative;
		z-index: 1;
		width: 70%;
		height: auto;
	}
	
	.no-touch &:hover,
	.no-touch &:focus,
	.touch &:active {
		&:before{
			transform: scale(1.1);
		}
	}
	
	.house &{
		display: none;
	}
	
	@include mq($until: desktop) {
		display: none;
	}
	
}

.house{
	
	height: 100vh;
	width: 100vw;
	max-width: 1600px;
	margin: 0 auto;
	
	display: flex;
	
}

.house__sidebar{
	
	width: percentage(1/3);
	
	&__form{
		width: 80%;
		margin: 0 auto;
		background-color: #fff;
		padding: 20px 20px 0 20px;
		border-radius: 0 0 20px 20px;
	}

}

.house__home{
	
	width: percentage(2/3);
	
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	justify-content: center;
	
	&__container{
		width: 90%;
	}
	
	&__interactive{
		
		@include aspect-ratio(1800,1457);
		background: transparent url('#{$image-path}/home/maison.png') 0 0 no-repeat;
		background-size: cover;
		
		&__dot{
			position: absolute;
			z-index: 2;
			top: 0;
			left: 0;
			height: 14px;
			width: 14px;
			
			&.house__home__interactive__dot--selected,
			.no-touch &.house__home__interactive__dot--hovered{
				z-index: 3;
				.house__home__interactive__dot__dot{
					transform: scale(1.5);
					border-width: 4px;
					border-color: $color-4;
					background-color: #fff;
				}
				.house__home__interactive__dot__label{
					opacity: 1;
					transform: translate(0,-50%) scale(1);
				}
			}
			
			&.house__home__interactive__dot--selected{
				.house__home__interactive__dot__label{
					border-color: $color-4;
				}
			}
			
			.no-touch &.house__home__interactive__dot--hovered{
				.house__home__interactive__dot__dot{
					border-width: 2px;
					background-color: $color-4;
				}
			}
			
			&--reverse{
				.house__home__interactive__dot__label{
					left: auto;
					right: -10px;
				}
			}
			
			&__dot{
				transition: transform 0.3s ease-in-out, border-width 0.1s ease-in-out;
				position: relative;
				z-index: 1;
				display: block;
				width: 14px;
				height: 14px;
				border-radius: 50%;
				border: 2px solid #fff;
				background-color: $color-4;
				cursor: pointer;
			}
			
			&__label{
				transition: opacity 0.3s ease-in-out, border-color 0.3s ease-in-out;
				position: absolute;
				z-index: 0;
				top: 50%;
				left: -10px;
				border: 1px solid #fff;
				background-color: #fff;
				border-radius: 50px;
				padding: 10px 35px;
				text-align: center;
				white-space: nowrap;
				transform: translate(0,-50%) scale(0.8);
				pointer-events: none;
				opacity: 0;
			}
		}
		
		&__image{
			transition: opacity 0.3s ease-in-out;
			position: absolute;
			z-index: 1;
			top: 0;
			left: 0;
			width: 100%;
			height: 100%;
			opacity: 0;
			
			img{
				width: 100%;
				height: 100%;
				object-fit: cover;
			}
			
			&.visible{
				opacity: 1;
			}
			
		}
		
	}
	
}