
.footer-main {
	height: 35px;
	padding: 0;
	position: fixed;
	z-index: 10;
	left: 0;
	bottom: 0;
	right: 0;
	
	background-color: $color-1;
	
	.house &{
		background-color: #fff;
	}
	
	@include mq($from: tablet) {
		height: 45px;
		padding: 0 20px;
	}
}

.nav-footer {
	color: $color-text-light;
	
	display: flex;
	flex-wrap: wrap;
	justify-content: space-around;
	
	li {
		display: inline-block;
		
		a {
			display: inline-block;
			height: 35px;
			line-height: 35px;
			
			background-color: $color-1;
			
			@include font-size(10);
			color: $color-text-light;
			font-weight: 300;
			
			.house &{
				background-color: #fff;
				color: $color-1;
			}
			
			.no-touch & {
				transition: background-color 0.2s ease-in-out, color 0.2s ease-in-out;
				
				&:hover, &:focus {
					color: #fff;
					background-color: lighten($color-1, 10%);
				}
			}
		}
	}
	
	@include mq($from: tablet) {
		float: right;
		li{
			a{
				padding: 0 10px;
				height: 45px;
				line-height: 45px;
				
				@include font-size(12);
			}
		}
	}
}

.mcube {
	@include mq($until: tablet) {
		display: none;
	}
	
	@include mq($from: tablet) {
		display: inline-block;
		position: absolute;
		width: 70px;
		height: 45px;
		top: 0;
		left: 20px;
		
		img{
			position: absolute;
			width: 70px;
			height: auto;
			top: 50%;
			left: 0;
			transform: translateY(-50%);
			opacity: .5;
		}
		
		span {
			display: inline-block;
			text-indent: -9999px;
		}
		
		.no-touch & {
			&:hover, &:focus {
				&:before {
					color: $color-text-light;
				}
			}
		}
	}
}