html { font-size: 62.5%; }

$font-base: 'Source Sans Pro', sans-serif;
$font-alt:  'Oswald', sans-serif;
$image-path: '/images/';

$color-1: #2C5296; // footer + tous les contenus, bordures, fonts, ...
$color-2: #2C5296; // Bleu
$color-3: #F6F6F6; // Fond details
$color-4: #00A94B; // Vert
$color-5: #D07E6C; // Orange

$color-bg:          #F0F0F0;
$color-text:        $color-1;
$color-text-light:  #fff;

$color-cancel: #bc2121;

$color-link: red;
$color-link-hover: blue;

$color-border: #96BBD6;

$color-facebook:    #3b5998;
$color-twitter:     #00c8fa;
$color-pinterest:   #cb2027;
$color-linkedin:    #0073b2;
$color-google-plus: #dd4b39;

$color-isolation-toiture:       #FF4436;
$color-isolation-mur:           #3AB3B2;
$color-fenetre:                 #607D8B;
$color-ventilation:             #3DA1D8;         
$color-chaudiere-condensation:  #5765B5;
$color-equipements-electriques: #2ECC71;
$color-solaire-thermique:       #FFC000;
$color-solaire-photovoltaique:  #D0E500;
$color-energie-bois:            #FF7E00;
$color-pompes-a-chaleur:        #7B1FA2;
$color-renovation-globale:      #E91E63;
$color-forage-geothermique:     #D283C3;

$white: #fff;
$black: #000;

$grid-gutter-base:    20px / 2;
$grid-gutter-tablet:  20px / 2;
$grid-gutter-desktop: 30px / 2;
$grid-gutter-wide:    30px / 2;