input:focus,
select:focus,
textarea:focus,
button:focus {
    outline: none;
}


$cc-color-bg:                 #fff;
$cc-color-border:             #ddd;
$cc-color-text:               #000;
$cc-color-btn:                #7DB537;
$cc-color-link:               #7DB537;
$cc-color-validated-checkbox: #7DB537;



/*@mixin cc-clearfix() {
	&::after {
		content: "";
		display: table;
		clear: both;
	}
}
*/

.cc-title {
	display: block;
	margin: 0;
	text-transform: uppercase;
	font-size: 16px;
	font-weight: bold;
	text-align: left;
	color: #000;
}

#cookie-banner .cc-title {
	margin-bottom: 10px;
}

.cc-intro {
	display: block;
	padding-bottom: 20px;
	/*border-bottom: 1px solid #ddd;*/
}


.cc-btn {
	text-align: center;
	display: inline-block;
	max-width: 240px;
	padding: 15px 20px;

	background-color: #7DB537;
	box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);

	font-family: Arial, sans-serif;
	text-transform: uppercase;
	color: #fff;
	font-size: 14px;
	font-weight: 700;

	transition: background-color 0.2s ease-in-out;
}

.no-touchevents .cc-btn:hover,
.no-touch .cc-btn:hover,
.no-touchevents .cc-btn:focus,
.no-touch .cc-btn:focus,
.touchevents .cc-btn:active,
.touch .cc-btn:active {
	background-color: #8fc64a;
	color: #fff;
}

.cc-btn-param {
	color: #7DB537;

}



.cc-btn-done {
	max-width: 280px;
	margin: 0;
	padding: 15px 20px;
}

.cc-btn-param {
	color: $cc-color-link;

	/*transform: translate(0, -50%);*/
	transition: color 0.2s ease-in-out;
}

#cookie-banner {
	display: block;
	width: 100%;
	padding: 30px;
	position: fixed;
	bottom: 0;
	left: 0;
	z-index: 1000;

	background-color: #fff;

	transform: translateY(110%);
	transition: transform 0.3s ease-out, opacity 0.3s ease-out;
	border-top: 1px solid #ddd;
}

#cookie-banner::after {
	content: "";
	display: table;
	clear: both;
}

#cookie-banner.show {
	transform: translateY(0);
}

#cookie-banner .cc-header {
	margin-bottom: 20px;
	text-align: center;
}

#cookie-banner .cc-body {
	margin-bottom: 20px;
}

@media (min-width: 700px) {
	#cookie-banner .cc-body {
		width: 70%;
		padding-right: 50px;
		margin-bottom: 0;
		float: left;
	}
}

#cookie-banner .cc-footer {
	text-align: center;
}

@media (min-width: 700px) {
	#cookie-banner .cc-footer {
		text-align: right;
		width: 30%;
		float: left;
	}
}

#cookie-banner .cc-title {
	text-align: center;
}

#cookie-banner .cc-btn-param {
	display: inline-block;
	margin-bottom: 10px;
	font-size: 0.9em;
}

@media (min-width: 520px) {
	#cookie-banner .cc-title {
		text-align: left;
	}
}


#cookie-consent {
	display: none; 
	width: 100%; 
	height: 100%;
	position: fixed;
	top: 0; 
	left: 0; 
	z-index: 1000;

	background: rgba(0, 0, 0, 0.8);
	
	color: black;
	font-family: Arial, sans-serif !important;
	font-size: 12px;
}

#cookie-consent .fixed {
	width: 100%;
	position: fixed;
	top: 0;
	left: 0;
}

#cookie-consent .fixed.bottom {
	top: auto;
	bottom: 0;
}

#cookie-consent p {
	margin: 0 0 1em 0;
	padding: 0;
}

#cookie-consent p:last-of-type {
	margin-bottom: 0;
}

#cookie-consent a:not(.cc-btn) {
	display: inline-block;
	margin-left: 8px;
	padding: 0 6px;
	color: #fff;

	border-radius: 0px;

	text-decoration: none;
}

#cookie-consent .cc-modal-inner {
	width: 90%;
	max-width: 600px;
	max-height: 90%;
	margin: 20px auto;
	padding: 0 0 92px 0;
	position: absolute;
	top: 50%;
	left: 50%;

	background: #fff;
	border-radius: 3px;

	transform: translate(-50%, -50%);
	font-size: 12px;
	line-height: 1.5;
}

#cookie-consent  .cc-main {
	display: none;
}

#cookie-consent .cc-param {
	position: relative;
}

#cookie-consent .cc-header {
	padding: 20px;
	border-bottom: 1px solid #ddd;
	background-color: #eee;
}

#cookie-consent .cc-body {
	display: block;
	width: 100%;
	height: auto;
	padding: 20px;
	position: relative;
	overflow-y: scroll;
}

#cookie-consent .cc-param .cc-body {
	height: calc(100vh - 340px);
	max-height: 380px;
	min-height: 250px;
}

#cookie-consent .cc-footer {
	width: 100%;
	height: 92px;
	padding: 20px;
	position: fixed;
	bottom: 0;
	left: 0;
	text-align: right;

	background-color: #eee;
	border-top: 1px solid #ddd;

	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);

}

.cc-main .cc-btn-param {
	display: block;
	position: absolute;
	top: 50%;
	left: 20px;

	transform: translateY(-50%);
}

#cookie-content .cc-btn.cc-btn-accept {
	position: absolute;
	top: 0;
	right: 0;
}

.no-touchevents .cc-btn-param:hover,
.no-touch .cc-btn-param:hover,
.no-touchevents .cc-btn-param:focus,
.no-touch .cc-btn-param:focus,
.touchevents .cc-btn-param:active,
.touch .cc-btn-param:active {
	color: #8fc64a;
}



#cookie-consent .cc-type {
	/*@include cc-clearfix;*/
	display: block;
	padding: 20px 0;
	position: relative;

	border-top: 1px solid #ddd;
}

#cookie-consent .cc-type:first-of-type {
	border-top: none;
}

#cookie-consent .cc-type label {
	display: block;
	width: 80%;
}

#cookie-consent .cc-type strong {
	display: block;
	margin-bottom: 10px;
	font-size: 13px;
	font-weight: 700;
}

#cookie-consent .cc-type input[type="checkbox"] {
	border-radius: 0;
	-webkit-appearance: none;
	-moz-appearance: none;
	position: absolute;
	right: 0;
	top: 20px;
	cursor: pointer;

	display: block;
	width: 44px;
	height: 22px;

	background-color: #ddd;
	border-radius: 22px;
	transform: scale(1);
}

#cookie-consent .cc-type input[type="checkbox"]:before {
	content: "";
	display: block;
	width: 18px;
	height: 18px;
	position: absolute;
	top: 2px;
	left: 2px;

	background-color: #fff;
	border-radius: 11px;
	box-shadow: 1px 1px 2px rgba(0, 0, 0, 0.2);
	transition: transform 0.1s ease-in-out, background-color 0.1s ease-in-out;
}

#cookie-consent .cc-type input[type="checkbox"]:after {
	content: "no";
	text-transform: uppercase;
	font-weight: bold;
	font-size: 11px;
	position: absolute;
	color: #444;
	top: 112%;
	left: 50%;
	transform: translateX(-50%);
}

[lang="fr"] #cookie-consent .cc-type input[type="checkbox"]:after { content: "non"; }
[lang="es"] #cookie-consent .cc-type input[type="checkbox"]:after { content: "no"; }
[lang="de"] #cookie-consent .cc-type input[type="checkbox"]:after { content: "nicht"; }
[lang="it"] #cookie-consent .cc-type input[type="checkbox"]:after { content: "no"; }

#cookie-consent .cc-type input[type="checkbox"]:disabled {
	opacity: 0.5;
	cursor: not-allowed;
}

#cookie-consent .cc-type input[type="checkbox"]:checked {
	background-color: #7DB537;
}

#cookie-consent .cc-type input[type="checkbox"]:checked:before {
	transform: translateX(22px);
}
#cookie-consent .cc-type input[type="checkbox"]:checked:after {
	content: "yes";
}

[lang="fr"] #cookie-consent .cc-type input[type="checkbox"]:checked:after { content: "oui"; }
[lang="es"] #cookie-consent .cc-type input[type="checkbox"]:checked:after { content: "sí"; }
[lang="de"] #cookie-consent .cc-type input[type="checkbox"]:checked:after { content: "ja"; }
[lang="it"] #cookie-consent .cc-type input[type="checkbox"]:checked:after { content: "sì"; }

.cc-alert {
	display: block;
	padding: 20px 20px 20px 20px;
	margin: 20px 0;

	background-color: #ddd;
	color: #000;

	a {
		color: #000;
		text-decoration: underline;
	}
}

.cc-alert.cc-alert--perf {
	
}

.cc-alert.cc-alert--perso {

}

.cc-alert.cc-alert--social {
	
}