.hidden { display: none !important;  }

.clear { clear: both; }

.align-left   { text-align: left; }
.align-center { text-align: center; }
.align-right  { text-align: right; }

.absolute-child {
	position: absolute;
	top: 0;
	right: 0;
	bottom: 0;
	left: 0;
}

@mixin vertical-align() {
	display: table;
	width: 100%;
	height: 100%;
}

@mixin vertical-align-inner() {
	display: table-cell;
	vertical-align: middle;
}

@mixin aspect-ratio($width, $height) {
	position: relative;
	
	&:before {
		display: block;
		content: "";
		width: 100%;
		padding-top: ($height / $width) * 100%;
	}
	
	> div {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
	}
}

.vertical-align {
	@include vertical-align();
}

.vertical-align-inner {
	@include vertical-align-inner();
}

.to-tablet {
	@include mq($from: tablet) {
		display: none;
	}
}

.to-desktop {
	@include mq($from: desktop) {
		display: none;
	}
}

.from-tablet {
	@include mq($until: tablet) {
		display: none;
	}
}

.from-desktop {
	@include mq($until: desktop) {
		display: none;
	}
}

%better-icon {
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin better-icon() {
	speak: none;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}